import { useCrisp } from "@mono/ui/src/composables/useCrisp";
import { locales, type Locale } from "@mono/constants/lib/locale";
import { useAuthStore } from "@/stores/auth";

export const useInitLocale = () => {
  const { locale } = useI18n();
  // get localStorage locale
  const localStorageLocale = localStorage.getItem("locale") as Locale | null;
  // detect user browser language
  const browserLocale = navigator.language.split("-")[0];
  if (locales.includes(localStorageLocale as Locale)) {
    // if locale is set in localStorage, init user locale with localStorage locale
    locale.value = localStorageLocale!;
    return { locale: localStorageLocale! };
  } else if (locales.includes(browserLocale as Locale)) {
    // if no locale is set in localStorage, init user locale with browser locale
    localStorage.setItem("locale", browserLocale);
    locale.value = browserLocale;
    return { locale: browserLocale };
  } else {
    // clear localStorage locale
    locale.value = "en";
    localStorage.removeItem("locale");
    return { locale: "en" };
  }
};

export const useLocaleWatcher = () => {
  const config = useRuntimeConfig();
  const { $urql, graphql } = useUrql();
  const { isLoggedIn } = useAuthStorage();
  const { crisp } = useCrisp();
  const { locale } = useI18n();
  const authStore = useAuthStore();

  locale.value = useInitLocale().locale;
  watch(locale, async (newLocale) => {
    localStorage.setItem("locale", newLocale);
    crisp.setup({
      isProd: config.public.API_TARGET === "production",
      locale: newLocale as unknown as Locale,
    });
    if (isLoggedIn.value) {
      const mutation = graphql(/* GraphQL */ `
        mutation LocaleSwitch($id: uuid!, $_set: users_set_input) {
          update_users_by_pk(pk_columns: { id: $id }, _set: $_set) {
            locale
          }
        }
      `);
      try {
        const { data } = await $urql
          .mutation(mutation, {
            id: authStore.userId,
            _set: {
              locale: newLocale,
            },
          })
          .toPromise();
        if (data) {
          await authStore.fetchUser();
        }
      } catch (error) {
        console.error("useLocaleWatcher ~ error:", error);
      }
    }
  });
};
